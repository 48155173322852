@import "variables";

.charts {
  > .chart-header {
    font-weight: $font-weight-bold;
    padding-bottom: map_get($spacers, 3);
    margin-bottom: map_get($spacers, 3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $border-width solid var(--bs-border-color);
  }
}

apx-chart {
  .apexcharts-gridline {
    stroke: var(--bs-border-color);
  }
}
