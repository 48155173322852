@import "variables";

@import "@amirsavand/ngx-input/assets/styles";

// Update design of ngx-input-label elements.
.ngx-input-label {
  font-size: $font-size-xs;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--bs-secondary);
}

// Truncation of ngx-input-select items.
.ngx-input-select > div {
  @extend .text-truncate;
}

// Add margin and shadow for ngx-input-select menu.
.ngx-input-select-menu {
  margin: map_get($spacers, 1) 0;
  box-shadow: $card-box-shadow;
}

// Limit height of ngx-input-select list.
.ngx-input-select-list {
  max-height: 500px;
  overflow-y: auto;
}

.list-group .ngx-input {
  margin: 0 !important;
}

// With this class, remove margin of ngx-input elements.
.ngx-input-no-margin {
  .ngx-input {
    margin: 0 !important;
  }
}

// In view mode for ngx-input-buttons hide buttons
// or items that are not selected.
.ngx-input-buttons {
  > .ngx-input-button {
    &.view-mode:not(.active) {
      display: none;
    }
  }
}

// In view mode for ngx-input-select, make the links
// colors act as normal link colors.
.ngx-input-select {
  .ngx-input-select-link {
    color: var(--bs-body-color);

    &:hover,
    &:focus {
      color: var(--bs-link-hover-color);
    }
  }
}
