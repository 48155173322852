@import "variables";

.btn {
  font-weight: $font-weight-bold;
  box-shadow: inset 0 -4px rgba(black, 0.1);
  line-height: 22.8px;

  &:not([class*="btn-outline-"]) {
    border: none;
  }

  &[disabled],
  &.disabled,
  &.active,
  &:active,
  &.btn-sm,
  &.btn-link,
  &.btn-no-shadow,
  &[class*="btn-outline-"] {
    box-shadow: none;
  }
}

// Turn light button into dark button in dark theme.
[data-bs-theme=dark] {
  .btn-light {
    @extend .btn-dark;
  }
}

// Turn dark button into light button in light theme.
[data-bs-theme=light] {
  .btn-dark {
    @extend .btn-light;
  }
}
