/* Background colors for each snackbar style. */
$snackbar-dark: #212529 !default;
$snackbar-light: #eeeeee !default;
$snackbar-success: #198754 !default;
$snackbar-info: #6f42c1 !default;
$snackbar-warning: #de9d00 !default;
$snackbar-danger: #dc3545 !default;

/* Text colors for each snackbar style (recommended to only set dark and light). */
$snackbar-dark-text: #ffffff !default;
$snackbar-light-text: $snackbar-dark !default;
$snackbar-success-text: #ffffff !default;
$snackbar-info-text: #ffffff !default;
$snackbar-warning-text: #ffffff !default;
$snackbar-danger-text: #ffffff !default;

/** Icons for each snackbar style. */
$snackbar-dark-icon: 'data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bell" class="svg-inline--fa fa-bell" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M256 32V51.2C329 66.03 384 130.6 384 208V226.8C384 273.9 401.3 319.2 432.5 354.4L439.9 362.7C448.3 372.2 450.4 385.6 445.2 397.1C440 408.6 428.6 416 416 416H32C19.4 416 7.971 408.6 2.809 397.1C-2.353 385.6-.2883 372.2 8.084 362.7L15.5 354.4C46.74 319.2 64 273.9 64 226.8V208C64 130.6 118.1 66.03 192 51.2V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32H256zM224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512z"></path></svg>' !default;
$snackbar-light-icon: $snackbar-dark-icon !default;
$snackbar-success-icon: 'data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z"></path></svg>' !default;
$snackbar-info-icon: 'data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info" class="svg-inline--fa fa-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z"></path></svg>' !default;
$snackbar-warning-icon: 'data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="triangle-exclamation" class="svg-inline--fa fa-triangle-exclamation" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>' !default;
$snackbar-danger-icon: 'data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation" class="svg-inline--fa fa-exclamation" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path fill="currentColor" d="M64 352c17.69 0 32-14.32 32-31.1V64.01c0-17.67-14.31-32.01-32-32.01S32 46.34 32 64.01v255.1C32 337.7 46.31 352 64 352zM64 400c-22.09 0-40 17.91-40 40s17.91 39.1 40 39.1s40-17.9 40-39.1S86.09 400 64 400z"></path></svg>' !default;

/** Box shadow */
$snackbar-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2) !default;
$snackbar-box-shadow-hover: 0 6px 6px rgba(0, 0, 0, 0.2) !default;

/** Sizing */
$snackbar-max-width: 100% !default;

/** Border radius */
$snackbar-border-radius: 100rem !default;
$snackbar-border-radius-icon: 100rem !default;
$snackbar-border-radius-button: 0.25rem !default;

/** Misc */
$snackbar-z-index: 1090 !default;
