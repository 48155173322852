@import "variables";

@import "logoing/src/logoing";

@include logoing-vertical-split("sb-loading", 32px, 1rem, 1500ms);

sb-loading {
  margin: auto;
}

[dir=rtl] sb-loading > div {
  scale: -1;
}
