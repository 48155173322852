@import "./variables";

.ngx-input {
  @extend .mb-3;

  .ngx-input-text.view-mode {
    white-space: pre-line;
  }

  .ngx-input-select {
    @extend .form-select;
    cursor: pointer;

    @at-root {
      [dir=rtl] & {
        padding: $form-select-padding-y $form-select-padding-x $form-select-padding-y $form-select-indicator-padding;
        background-position: left $form-select-padding-x center;
      }
    }

    &.ngx-input-select-multi-selected,
    &.ngx-input-select-single-selected {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: map_get($spacers, 2);
    }

    &.ngx-input-select-open {
      &:not(.is-invalid) {
        border-color: $input-focus-border-color;
        box-shadow: $input-focus-box-shadow;
      }

      &.is-invalid {
        border-color: $form-invalid-border-color;
        box-shadow: 0 0 0 0.25rem rgba($danger, 0.25);
      }
    }

    &.view-mode {
      pointer-events: none;
      cursor: default;
      border-color: transparent;
      padding-left: 0;
      padding-right: 0;
      background-image: none;

      &.unset {
        color: $input-view-mode-select-unset-placeholder-color;
      }

      > a {
        pointer-events: all;
      }
    }
  }

  .ngx-input-file {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: map_get($spacers, 2);
    cursor: pointer;
    position: relative;

    &.disabled {
      pointer-events: none !important;
    }

    img {
      position: absolute;
      height: $ngx-input-height;
      border-radius: $input-border-radius;
      width: auto;
      padding: calc(#{$input-border-width} * 2);

      @at-root {

        [dir=ltr] & {
          left: calc(#{$input-border-width} * -1);
        }

        [dir=rtl] & {
          right: calc(#{$input-border-width} * -1);
        }
      }
    }

    &.view-mode {
      img {
        padding: 0;
      }
    }

    .ngx-input-file-clear {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url($input-clear-icon);
      display: block;
      filter: invert(0.5);

      @at-root {

        [dir=ltr] & {
          margin-left: auto;
        }

        [dir=rtl] & {
          margin-right: auto;
        }
      }
    }
  }

  .ngx-input-label {
    @extend .mb-1;
    display: inline-block;
  }

  .ngx-input-element {
    @extend .form-control;
    min-height: $ngx-input-height;

    &:hover,
    &:focus {
      &:not(.is-invalid) {
        border-color: $input-focus-border-color;
      }
    }

    &.disabled,
    &[disabled] {
      background-color: $input-disabled-bg;
      pointer-events: none;
    }

    @if ($input-view-mode) {
      &.view-mode {
        background: none;
        border-color: transparent !important;
        padding-left: 0;
        padding-right: 0;
        box-shadow: none !important;
        cursor: default;
      }
    }

    &[type=color] {
      min-height: $ngx-input-height;
      padding: 0;
      border: none;
      overflow: hidden;
    }
  }

  .ngx-input-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: map_get($spacers, 1);

    > .ngx-input-button {
      @extend .btn;
      @extend .btn-outline-primary;
      min-height: $ngx-input-height;

      @if ($input-view-mode) {
        &.view-mode {
          pointer-events: none;
          cursor: default;

          &:not(.active) {
            display: none;
          }
        }
      }
    }
  }

  .ngx-input-stars {

    &.readonly,
    &.view-mode {
      .ngx-input-star {
        pointer-events: none !important;
      }
    }

    > .ngx-input-stars-wrapper {
      display: flex;
      align-items: center;

      > .ngx-input-star {
        width: $ngx-input-height;
        height: $ngx-input-height;
        background-image: url($input-star-icon);
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.4;
        filter: grayscale(1);
        cursor: pointer;

        &.ngx-input-star-fill {
          opacity: 1;
          filter: none;
        }
      }
    }
  }

  .ngx-input-error {
    @extend .text-bg-danger;
    display: inline-block;
    font-size: $font-size-sm;
    padding: 0 $badge-padding-x;
    border-radius: $input-border-radius;
  }
}

.ngx-input-icon {
  border-radius: $input-border-radius;

  @at-root {

    [dir=ltr] & {
      margin-right: map_get($spacers, 2);
    }

    [dir=rtl] & {
      margin-left: map_get($spacers, 2);
    }
  }
}

.ngx-input-select-menu {
  @extend .card;
  max-width: $input-menu-max-width;
  min-width: $input-menu-min-width;

  .cdk-overlay-pane:not(.panel-above) & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transform-origin: top center;
  }

  .panel-above & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transform-origin: bottom center;
  }

  .ngx-input-select-header {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .card-body;
    @extend .p-3;
    @extend .border-bottom;
    gap: map_get($spacers, 2);

    .ngx-input-select-search {
      width: 100%;

      input {
        @extend .form-control;
      }
    }

    button {
      @extend .btn;
      @extend .btn-primary;
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      flex-shrink: 0;
      height: $ngx-input-height;
      width: $ngx-input-height;
      padding: 0;

      .ngx-input-add {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url($input-add-icon);
        filter: invert(1);
        display: block;
      }
    }
  }

  .ngx-input-select-list {
    @extend .list-group;
    @extend .list-group-flush;
    border-top: none;
    max-height: $input-menu-max-height;
    overflow-y: auto;
  }

  .ngx-input-select-item {
    @extend .list-group-item;
    @extend .list-group-item-action;
    @extend .border-0;
    @extend .d-flex;
    @extend .align-items-center;
    gap: map_get($spacers, 2);
    word-break: break-word;
    cursor: pointer;

    &.ngx-input-select-item-loading {
      pointer-events: none;
    }

    .ngx-input-select-icon-loading,
    .ngx-input-select-icon-selected,
    .ngx-input-select-icon-clear {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      flex-shrink: 0;

      @at-root {

        [dir=ltr] & {
          @extend .ms-auto;
        }

        [dir=rtl] & {
          @extend .me-auto;
        }
      }
    }

    .ngx-input-select-icon-selected {
      background-color: $input-icon-selected;
      box-shadow: 0 0 0 4px rgba($input-icon-selected, 0.25);
    }

    .ngx-input-select-icon-clear {
      background-color: $input-icon-clear;
      box-shadow: 0 0 0 4px rgba($input-icon-clear, 0.25);
    }

    .ngx-input-select-icon-loading {
      background-color: $input-icon-loading;
      box-shadow: 0 0 0 4px rgba($input-icon-loading, 0.25);
      margin: auto;
      animation: ngx-input-loading infinite 500ms;
    }
  }

  .ngx-input-select-text {
    @extend .py-2;
    @extend .px-3;
    @extend .small;
    @extend .text-muted;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1070;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1070;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1070;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

@keyframes ngx-input-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
