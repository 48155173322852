@import "variables";

.page-header {
  display: flex;
  align-items: center;
  padding: 0 map_get($spacers, 3);
  height: $sidebar-header-height;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
  gap: map_get($spacers, 3);
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  z-index: 1;
  position: relative;

  .header-block {
    width: 42px;
    height: 42px;
    border-radius: var(--bs-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    &.header-block-image {
      cursor: pointer;
    }

    &.header-block-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      background: rgba($primary, 0.12);
    }
  }
}
