@import "variables";

a {
  cursor: pointer;

  &.disabled {
    pointer-events: none;

    &:not(.list-group-item) {
      opacity: $btn-disabled-opacity;
    }
  }
}

// Handle badges that are clickable.
// Must always use .bg-text-xyz format for their classes.
a.badge {
  font-weight: $font-weight-normal;

  &:focus,
  &:hover {
    box-shadow: inset 0 0 0 10rem rgba(black, 0.2);
  }

  &:active {
    box-shadow: inset 0 0 0 10rem rgba(black, 0.4);
  }

  &:active:not(.active) {
    position: relative;
    top: 2px;
  }

  &.active {
    font-weight: $font-weight-bold;
  }
}
