@import "variables";

[sbsrchandler] {
  transition: $transition-base;
  opacity: 0;

  &.src-loaded {
    opacity: 1;
  }
}
