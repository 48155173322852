@mixin logoing-vertical-split($selector, $size, $movement, $duration, $image:"") {
  @keyframes even {
    0% {
      translate: 0 0;
    }
    12.5% {
      translate: 0 $movement;
    }
    25% {
      translate: 0 0;
    }
    37.5% {
      translate: 0 -#{$movement};
    }
    50%,
    100% {
      translate: 0 0;
    }
  }

  #{$selector} {
    height: $size;
    width: $size;
    display: flex;

    > div {
      @if ($image != "") {
        background-image: url($image);
      }
      width: 25%;
      background-size: $size $size;
      background-repeat: no-repeat;
      animation: even $duration ease-in-out infinite;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          animation-delay: 250ms * $i;
          background-position-x: calc((-#{$size} / 4) * (#{$i} - 1));
        }
      }
    }
  }
}
