@import "./variables";

ngx-snackbar {
  position: fixed;
  bottom: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  place-items: flex-end;
  pointer-events: none;
  z-index: $snackbar-z-index;
  max-width: 100%;


  @at-root {

    [dir=ltr] & {
      right: 0;
    }

    [dir=rtl] & {
      left: 0;
    }
  }

  > .ngx-snackbar {
    margin-top: 15px;
    padding: 5px;
    border-radius: $snackbar-border-radius;
    display: flex;
    align-items: center;
    box-shadow: $snackbar-box-shadow;
    max-width: $snackbar-max-width;
    overflow: hidden;
    transition: box-shadow 100ms ease-in-out;
    pointer-events: all;

    &.ngx-snackbar-style-dark {
      color: $snackbar-dark-text;
      background-color: $snackbar-dark;

      > .ngx-snackbar-icon > .ngx-snackbar-icon-inner {
        background-image: url($snackbar-dark-icon);
      }
    }

    &.ngx-snackbar-style-light {
      color: $snackbar-light-text;
      background-color: $snackbar-light;

      > .ngx-snackbar-icon > .ngx-snackbar-icon-inner {
        background-image: url($snackbar-light-icon);
      }
    }

    &.ngx-snackbar-style-success {
      color: $snackbar-success-text;
      background-color: $snackbar-success;

      > .ngx-snackbar-icon > .ngx-snackbar-icon-inner {
        background-image: url($snackbar-success-icon);
      }
    }

    &.ngx-snackbar-style-info {
      color: $snackbar-info-text;
      background-color: $snackbar-info;

      > .ngx-snackbar-icon > .ngx-snackbar-icon-inner {
        background-image: url($snackbar-info-icon);
      }
    }

    &.ngx-snackbar-style-warning {
      color: $snackbar-warning-text;
      background-color: $snackbar-warning;

      > .ngx-snackbar-icon > .ngx-snackbar-icon-inner {
        background-image: url($snackbar-warning-icon);
      }
    }

    &.ngx-snackbar-style-danger {
      color: $snackbar-danger-text;
      background-color: $snackbar-danger;

      > .ngx-snackbar-icon > .ngx-snackbar-icon-inner {
        background-image: url($snackbar-danger-icon);
      }
    }

    &.ngx-snackbar-dismissible {
      cursor: pointer;

      &:hover {
        box-shadow: $snackbar-box-shadow-hover;
      }

      &:active {
        box-shadow: none;
      }
    }

    > .ngx-snackbar-icon {
      border-radius: $snackbar-border-radius-icon;
      background: $snackbar-dark;
      padding: 8px;
      align-self: flex-start;

      > .ngx-snackbar-icon-inner {
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        filter: invert(1);
      }
    }

    > .ngx-snackbar-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @at-root {

        [dir=ltr] & {
          margin-left: 8px;
          margin-right: 16px;
        }

        [dir=rtl] & {
          margin-right: 8px;
          margin-left: 16px;
        }
      }
    }

    > .ngx-snackbar-buttons {
      margin: 0 5px;
      display: flex;
      align-items: center;
      gap: 5px;

      > .ngx-snackbar-button {
        overflow: hidden;
        background: rgba(white, 0.1);
        color: inherit;
        border-radius: $snackbar-border-radius-button;
        border: none;
        text-transform: uppercase;
        padding: 2px 10px;
        outline: none !important;

        &:hover,
        &:focus {
          background: rgba(white, 0.2);
        }

        &:active {
          background: rgba(white, 0.3);
        }
      }
    }
  }
}
