@use "sass:math";

/** region Bootstrap - Functions */

@import "bootstrap/scss/functions";

/** endregion */

/** region Library - Colors */

$primary: #575fcf !default;
$dark: #1e272e !default;
$secondary: #808e9b !default;
$light: #d2dae2 !default;
$danger: #ff5e57 !default;
$success: #05c46b !default;
$info: #0fbcf9 !default;
$white: #ffffff !default;
$warning: #ffb02f !default;

$border-color: #e9ecef !default;
$border-color-dark: #343a40 !default;

$min-contrast-ratio: 2.2 !default;

/** endregion */

/** region Library - Border Radius */

$border-radius: 3px !default;
$border-radius-lg: 0.5rem !default;

/** endregion */

/** region Library - Font */

$font-family-base: "Inter", sans-serif !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;

$font-size-base: 0.95rem !default;
$font-size-xs: $font-size-base * 0.75 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

/** endregion */

/** region Library - Box Shadow */

$box-shadow: 0 .05rem 1rem rgba(black, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba(black, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba(black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba(black, 0.075) !default;

/** endregion */

/** region Library - Link */

$link-decoration: none !default;
$link-hover-decoration: none !default;

/** endregion */

/** region Library - Nav Link */

$nav-link-color: $secondary !default;
$nav-link-hover-color: $primary !default;
$nav-pills-link-active-bg: rgba($primary, 0.12) !default;
$nav-pills-link-active-color: $primary !default;
$nav-link-transition: none !default;
$nav-pills-border-radius: $border-radius-lg !default;

/** endregion */

/** region Library - HR */

$hr-opacity: 0.1 !default;

/** endregion */

/** region Library - Button */

$btn-font-size: $font-size-sm !default;
$btn-font-size-sm: $font-size-xs !default;
$btn-font-size-lg: $font-size-base !default;

/** endregion */

/** region Library - Modal */

$modal-content-border-radius: $border-radius !default;

/** endregion */

/** region Library - Card */

$card-border-radius: $border-radius-lg !default;
$card-box-shadow: 0 10px 12px rgba(black, 0.05) !default;

/** endregion */

/** region Library - Dropdown */

$dropdown-box-shadow: $box-shadow !default;

/** endregion */

/** region Library - Editor */

$editor-suggestion-max-height: 300px !default;
$editor-suggestion-max-width: unset !default;
$editor-suggestion-offset: 8px !default;
$editor-suggestion-icon-size: 32px !default;

/** endregion */

/** region Bootstrap - Configurations */

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/** endregion */

/** region NGX Snackbar */

$snackbar-dark: $dark !default;
$snackbar-light: $white !default;
$snackbar-success: $success !default;
$snackbar-info: $info !default;
$snackbar-warning: $warning !default;
$snackbar-danger: $danger !default;

@import "@amirsavand/ngx-snackbar/assets/variables";

/** endregion */

/** region NGX Input */

$input-menu-max-width: 300px !default;
$input-menu-min-width: 300px !default;
$input-icon-selected: var(--bs-primary) !default;
$input-icon-clear: var(--bs-danger) !default;

@import "@amirsavand/ngx-input/assets/variables";

/** endregion */

/** region NGX Modal */

@import "@amirsavand/ngx-modal/assets/variables";

/** endregion */

/** region Library - Sidebar */

$sidebar-width: 300px !default;
$sidebar-width-collapsed: 80px !default;
$sidebar-item-size: 56px !default;
$sidebar-header-height: 75px !default;
$sidebar-toggler-size: 28px !default;
$sidebar-scrollbar-width: 2px !default;
$sidebar-spacing: map_get($spacers, 3) !default;
$sidebar-group-spacing: math.div(($sidebar-width-collapsed - $sidebar-item-size), 2) !default;
$sidebar-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;

/** endregion */

/** region Library - Scrollbar */

@mixin scrollbar($width: 5px, $color: var(--bs-border-color)) {
  @include media-breakpoint-up(sm) {
    &::-webkit-scrollbar {
      width: $width;
      height: $width;
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: $color;
    }
  }
}

/** endregion */
