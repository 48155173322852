$ngx-input-height: 38px !default;
$input-view-mode: true !default;
$input-view-mode-select-unset-placeholder-color: $secondary !default;
$input-disabled-bg: #e9ecef !default;
$input-add-icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktcGx1cy1sZyIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDJhLjUuNSAwIDAgMSAuNS41djVoNWEuNS41IDAgMCAxIDAgMWgtNXY1YS41LjUgMCAwIDEtMSAwdi01aC01YS41LjUgMCAwIDEgMC0xaDV2LTVBLjUuNSAwIDAgMSA4IDJaIi8+Cjwvc3ZnPg==" !default;
$input-star-icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMC4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzgxLjIgMTUwLjNMNTI0LjkgMTcxLjVDNTM2LjggMTczLjIgNTQ2LjggMTgxLjYgNTUwLjYgMTkzLjFDNTU0LjQgMjA0LjcgNTUxLjMgMjE3LjMgNTQyLjcgMjI1LjlMNDM4LjUgMzI4LjFMNDYzLjEgNDc0LjdDNDY1LjEgNDg2LjcgNDYwLjIgNDk4LjkgNDUwLjIgNTA2QzQ0MC4zIDUxMy4xIDQyNy4yIDUxNCA0MTYuNSA1MDguM0wyODguMSA0MzkuOEwxNTkuOCA1MDguM0MxNDkgNTE0IDEzNS45IDUxMy4xIDEyNiA1MDZDMTE2LjEgNDk4LjkgMTExLjEgNDg2LjcgMTEzLjIgNDc0LjdMMTM3LjggMzI4LjFMMzMuNTggMjI1LjlDMjQuOTcgMjE3LjMgMjEuOTEgMjA0LjcgMjUuNjkgMTkzLjFDMjkuNDYgMTgxLjYgMzkuNDMgMTczLjIgNTEuNDIgMTcxLjVMMTk1IDE1MC4zTDI1OS40IDE3Ljk3QzI2NC43IDYuOTU0IDI3NS45LS4wMzkxIDI4OC4xLS4wMzkxQzMwMC40LS4wMzkxIDMxMS42IDYuOTU0IDMxNi45IDE3Ljk3TDM4MS4yIDE1MC4zeiIgZmlsbD0iI2ZmZDQ2NiIvPjwvc3ZnPg==" !default;
$input-clear-icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmkteC1sZyIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNMi4xNDYgMi44NTRhLjUuNSAwIDEgMSAuNzA4LS43MDhMOCA3LjI5M2w1LjE0Ni01LjE0N2EuNS41IDAgMCAxIC43MDguNzA4TDguNzA3IDhsNS4xNDcgNS4xNDZhLjUuNSAwIDAgMS0uNzA4LjcwOEw4IDguNzA3bC01LjE0NiA1LjE0N2EuNS41IDAgMCAxLS43MDgtLjcwOEw3LjI5MyA4IDIuMTQ2IDIuODU0WiIvPgo8L3N2Zz4=" !default;
$input-menu-max-width: 240px !default;
$input-menu-min-width: 240px !default;
$input-menu-max-height: 300px !default;
$input-icon-selected: $primary !default;
$input-icon-clear: $danger !default;
$input-icon-loading: $warning !default;
