@import "variables";

.ngx-input-element-focus {
  &:not(.is-invalid) {
    border-color: $input-focus-border-color !important;
    box-shadow: $input-focus-box-shadow;
  }

  &.is-invalid {
    border-color: $form-invalid-border-color !important;
    box-shadow: 0 0 0 0.25rem rgba($danger, 0.25);
  }
}

.small-screen {
  .editor-buttons {
    .btn {
      @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size-lg, $btn-border-radius-lg);
    }
  }
}

.editor-wrapper {
  display: flex;
  flex-direction: column;

  tiptap-editor {
    flex: 1;
    min-width: 0;
  }

  .editor-buttons {
    cursor: text;
  }
}

.ProseMirror-focused {
  outline: none !important;
}

.editor-preview {
  overflow-wrap: break-word;
}

.editor-preview,
.ProseMirror,
.ngx-input-type-editor .ngx-input-element {

  .mention,
  [data-type="emoji"] {
    display: inline-block;
    user-select: all;

    span {
      display: block;
      user-select: none;
    }
  }

  /**
   * This style fixes issue where we can't select text when paragraph starts/ends with
   * an inline node (Emoji, Mentions, etc.).
   *
   * Removing `width` style in this decorator (.ProseMirror-separator), fixes the issue.
   * In TipTap, someone added `width: 1px; height: 1px` to that decorator's style, which caused this issue.
   * There's a pull request in TipTap already to remove this https://github.com/ueberdosis/tiptap/pull/4646.
   *
   * See https://task.savandbros.com/1/CHAT/40
   */
  img.ProseMirror-separator {
    width: unset !important;
  }

  blockquote {
    margin-bottom: 0;
    margin-top: map_get($spacers, 2);
    border: 0;
    border-left: 4px solid rgba(#000, 0.3);
    padding: map_get($spacers, 2) map_get($spacers, 3);
    border-radius: $border-radius;
    background: rgba(#000, 0.1);
    font-size: $font-size-sm;

    &:first-child {
      margin: 0;
    }
  }

  code {
    background: rgba(#000, 0.1);
    border-radius: $border-radius;
    padding: map_get($spacers, 1) / 2 map_get($spacers, 1);
    font-size: $font-size-sm;
  }

  pre {
    margin-bottom: 0;
    margin-top: map_get($spacers, 2);
    padding: 15px;
    background: rgba(#000, 0.1);
    color: inherit;
    border-radius: $border-radius;
    font-size: $font-size-sm;

    &:first-child {
      margin: 0;
    }

    > code {
      color: unset;
      background: unset;
      border-radius: unset;
      padding: unset;
      font-size: unset;
    }
  }

  ul,
  ol {
    margin-bottom: 0;
    margin-top: map_get($spacers, 2);
    padding-left: map_get($spacers, 4);

    &:first-child {
      margin: 0;
    }

    li + li {
      margin-top: map_get($spacers, 1);
    }

    ul:not(:first-child),
    ol:not(:first-child) {
      margin-top: map_get($spacers, 1);
    }
  }

  b,
  strong {
    font-weight: $font-weight-bold;
  }

  p:empty::after {
    content: "\00A0";
  }

  blockquote,
  pre,
  p {
    margin-bottom: 0;
    margin-top: map_get($spacers, 2);

    &:first-child {
      margin: 0;
    }
  }
}

sb-editor {
  sb-formatting-toolbar {
    cursor: text;
    display: flex;
    align-items: center;
    gap: map_get($spacers, 1);
    overflow-x: auto;

    .btn {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
    }
  }

  .switchable {
    sb-formatting-toolbar {
      margin-bottom: 0;
    }
  }

  .ProseMirror {
    overflow: auto;
    @include scrollbar();
    max-height: 300px;
    word-break: break-word;
  }

  .tiptap p.is-editor-empty:first-child::before {
    color: $input-placeholder-color;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}


.editor-buttons {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: map_get($spacers, 1);
  overflow: hidden;

  .btn {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size, $btn-border-radius-sm);
  }

  > div {
    min-width: 0;
  }

  .switchable,
  .inner {
    display: flex;
    align-items: center;
    gap: map_get($spacers, 1);
  }
}

.vr {
  height: 24px;
  margin: 0 map_get($spacers, 1);
  align-self: center;
  flex-shrink: 0;
}

.btn.toolbar-button {
  color: var(--bs-secondary-color);
  --bs-btn-hover-bg: rgba(var(--bs-body-color-rgb), 0.04);
  --bs-btn-active-bg: rgba(var(--bs-body-color-rgb), 0.12);

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: var(--bs-body-color);
  }

  &.active:hover {
    --bs-btn-active-bg: rgba(var(--bs-body-color-rgb), 0.16);
  }
}

[data-bs-theme="dark"] {
  .editor-preview,
  .ProseMirror,
  .ngx-input-type-editor .ngx-input-element {
    blockquote {
      background: rgba(#fff, 0.1);
      border-left-color: rgba(#fff, 0.5);
    }

    pre,
    code {
      background: #111;
    }
  }
}

sb-suggestions {
  .wrapper {
    margin-top: $editor-suggestion-offset;
    margin-bottom: $editor-suggestion-offset;
    max-height: $editor-suggestion-max-height;
    overflow-y: auto;
    height: calc(100% - (#{$editor-suggestion-offset} * 2));
    max-width: $editor-suggestion-max-width;

    .cdk-menu-item {
      gap: map_get($spacers, 2);
      text-align: left;

      img {
        width: $editor-suggestion-icon-size;
        height: $editor-suggestion-icon-size;
        border-radius: $border-radius;
      }
    }

    .cdk-overlay-pane:not(.panel-above) & {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      transform-origin: top center;
    }

    .panel-above & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transform-origin: bottom center;
    }

    .emoji {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      font-size: 24px;
    }
  }
}
