/* Variables */

@import 'variables';

/** Angular CRK Text Field */

@import '@angular/cdk/text-field-prebuilt.css';

/** Bootstrap */

@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
//@import "bootstrap/scss/images";
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
//@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/forms/labels';
@import 'bootstrap/scss/forms/form-text';
@import 'bootstrap/scss/forms/form-control';
@import 'bootstrap/scss/forms/form-select';
//@import 'bootstrap/scss/forms/form-check';
//@import 'bootstrap/scss/forms/form-range';
//@import 'bootstrap/scss/forms/floating-labels';
//@import 'bootstrap/scss/forms/input-group';
@import 'bootstrap/scss/forms/validation';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
//@import "bootstrap/scss/button-group";
@import 'bootstrap/scss/nav';
//@import "bootstrap/scss/navbar";
@import 'bootstrap/scss/card';
//@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
@import 'bootstrap/scss/badge';
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
@import 'bootstrap/scss/list-group';
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import 'bootstrap/scss/modal';
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/utilities/api';

/** region Savand Bros NGX Common */

@import '@SavandBros/savandbros-ngx-common/assets/apex-charts';
@import '@SavandBros/savandbros-ngx-common/assets/button';
@import '@SavandBros/savandbros-ngx-common/assets/card';
@import '@SavandBros/savandbros-ngx-common/assets/cdk-menu';
@import '@SavandBros/savandbros-ngx-common/assets/colors';
@import '@SavandBros/savandbros-ngx-common/assets/cursor';
@import '@SavandBros/savandbros-ngx-common/assets/editor';
@import '@SavandBros/savandbros-ngx-common/assets/font';
@import '@SavandBros/savandbros-ngx-common/assets/form';
@import '@SavandBros/savandbros-ngx-common/assets/general-error';
@import '@SavandBros/savandbros-ngx-common/assets/image-placeholder';
@import '@SavandBros/savandbros-ngx-common/assets/link';
@import '@SavandBros/savandbros-ngx-common/assets/logoing';
@import '@SavandBros/savandbros-ngx-common/assets/max-width';
@import '@SavandBros/savandbros-ngx-common/assets/ngx-emoji-mart';
@import '@SavandBros/savandbros-ngx-common/assets/ngx-input';
@import '@SavandBros/savandbros-ngx-common/assets/ngx-modal';
@import '@SavandBros/savandbros-ngx-common/assets/ngx-snackbar';
@import '@SavandBros/savandbros-ngx-common/assets/page-header';
@import '@SavandBros/savandbros-ngx-common/assets/show-hover-wrapper';
@import '@SavandBros/savandbros-ngx-common/assets/src-handler';
@import '@SavandBros/savandbros-ngx-common/assets/table';
@import '@SavandBros/savandbros-ngx-common/assets/text';

/** endregion */

/** Messages */

@mixin messageTransition {
  transition-property: background-color, color;
  transition-duration: 150ms, 1000ms;
  transition-timing-function: ease-in-out;
}

:root {
  --message-bg: var(--bs-dark-bg-subtle);
  --message-border-color: var(--bs-border-color);
  --message-hover-bg: var(--bs-body-bg);
  --message-active-bg: var(--bs-light-bg-subtle);
}

.messages {
  @include scrollbar(8px, var(--message-border-color));
}

.message {
  @include messageTransition;
  background: var(--message-bg);
  display: flex;
  padding: map_get($spacers, 2) map_get($spacers, 3);
  position: relative;

  &:hover:not(.no-hover),
  &:focus,
  &.is-hover {
    background: var(--message-hover-bg);

    .message-actions .message-action {
      display: block;
    }
  }

  &:first-child,
  &.is-header {
    .message-actions {
      translate: 0 0;
    }
  }

  &.is-highlight {
    background: rgba($primary, 0.12);
  }

  &.is-unread,
  &.is-unread-reply {
    .message-member {
      color: var(--bs-warning);
    }
  }

  &.is-unread-reply {
    .message-badge-replies {
      background: var(--bs-warning-bg-subtle) !important;
      color: var(--bs-warning-text-emphasis) !important;
    }
  }

  &.is-select {
    background: rgba($warning, 0.12);
  }

  &.is-loading {
    .message-actions .message-action {
      display: none;
    }
  }

  .message-start {
    margin-right: map_get($spacers, 3);
    width: 42px;
    height: 42px;
    border-radius: $border-radius;
    flex-shrink: 0;
  }

  .message-end {
    width: 100%;
    min-width: 0;

    .message-title {
      display: flex;
      align-items: flex-end;

      .message-member {
        @include messageTransition;
        font-weight: $font-weight-bold;
        cursor: pointer;
      }

      .message-created {
        color: $secondary;
        font-size: $font-size-sm;
        margin-left: map_get($spacers, 2);
      }
    }

    .message-attachments {
      display: flex;
      flex-wrap: wrap;
      gap: map_get($spacers, 2);
      margin-top: map_get($spacers, 1);

      .message-attachment {
        &.message-attachment-interactive {
          width: 128px;
          height: 128px;
          border-radius: $border-radius;
          padding: map_get($spacers, 2);
          background: rgba($primary, 0.12);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: map_get($spacers, 3);
          overflow: hidden;
          cursor: pointer;

          &:hover,
          &:focus {
            outline: 1px solid $primary;
          }

          &:active {
            position: relative;
            top: 3px;
          }
        }

        &.message-attachment-voice {
          width: 100%;
        }

        > fa-icon {
          font-size: 2rem;
          color: var(--bs-primary);
        }

        > div {
          max-width: 100%;
          font-size: $font-size-sm;
          color: var(--bs-secondary);
        }
      }
    }

    .message-badges {
      display: flex;
      flex-wrap: wrap;

      .message-badge {
        background: var(--bs-border-color);
        color: var(--bs-body-color);
        border: $border-width solid transparent;
        margin-right: map_get($spacers, 1);
        margin-top: map_get($spacers, 1);
        cursor: pointer;
        height: 28px;
        padding: 0 map_get($spacers, 2);
        font-size: $font-size-sm;
        border-radius: $border-radius;
        display: flex;
        align-items: center;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &.message-badge.message-badge-info {
          cursor: unset;
        }

        &:not(.message-badge.message-badge-info) {
          &:hover,
          &:focus {
            background: transparent;
            color: var(--bs-body-color);
            border-color: var(--bs-primary);
          }

          &.active {
            background: var(--bs-primary);
            color: #fff;
            border-color: var(--bs-primary);
          }

          &:active {
            position: relative;
            top: 2px;
          }
        }

        &.message-badge-replies {
          @include messageTransition;

          > .replies-members img {
            width: 20px;
            height: 20px;
            border-radius: $border-radius;
            margin-left: map_get($spacers, 1);

            &:first-child {
              margin-left: 0;
            }
          }

          > .replies-count {
            margin-left: map_get($spacers, 2);
          }

          > .replies-text {
            margin-left: map_get($spacers, 1);
          }

          > .replies-last {
            margin-left: map_get($spacers, 2);
            opacity: 0.5;
          }
        }

        &.message-badge-replying {
          gap: map_get($spacers, 1);
          background: var(--bs-warning-bg-subtle);
          color: var(--bs-warning-text-emphasis);

          img {
            width: 20px;
            height: 20px;
            border-radius: $border-radius;
          }
        }
      }
    }
  }

  .message-actions {
    display: flex;
    position: absolute;
    right: map_get($spacers, 3);
    top: 0;
    translate: 0 -50%;
    padding: map_get($spacers, 1);

    .message-action,
    .message-action-more {
      display: none;
    }

    .btn {
      color: $secondary;
      margin-left: map_get($spacers, 2);
      background: var(--message-hover-bg);
      font-size: $font-size-base;

      &:hover,
      &:focus {
        background: var(--message-active-bg);
      }

      &:active {
        position: relative;
        top: 2px;
      }
    }
  }
}

// noinspection SassScssResolvedByNameOnly
@include media-breakpoint-down(md) {
  .message {
    .message-actions {
      .message-action-more {
        display: block;
      }

      .message-action {
        display: none !important;
      }

      &.message-actions-open .message-action {
        display: block !important;
      }
    }
  }
}

/** Message parser */

.message-content {
  .message-badges {
    margin: map_get($spacers, 2);
    margin-top: 0;
  }
}

/** Editor */

sb-editor {
  sb-formatting-toolbar {
    padding: map_get($spacers, 2) map_get($spacers, 2) 0;
  }

  .editor-buttons {
    padding: 0 map_get($spacers, 2) map_get($spacers, 2);

    sb-formatting-toolbar {
      padding: 0;
    }
  }

  .ProseMirror {
    padding: map_get($spacers, 2) map_get($spacers, 3);
  }

  @include media-breakpoint-down(sm) {
    sb-formatting-toolbar {
      gap: 1px;
      padding-left: map_get($spacers, 1);
      padding-right: map_get($spacers, 1);
    }

    .ProseMirror {
      padding: map_get($spacers, 3);
    }
  }
}

.editor-preview,
sb-editor {
  .mention {
    background: var(--bs-light-bg-subtle);
    color: var(--bs-primary-text-emphasis);
    border-radius: $border-radius;
    padding: 0 0.3rem;
    word-break: break-word;
  }
}
