@import "variables";

@import "@amirsavand/ngx-snackbar/assets/styles";

ngx-snackbar {
  > .ngx-snackbar:not(.ng-animating) {
    > .ngx-snackbar-text {
      white-space: unset;
    }
  }
}

@include media-breakpoint-down(sm) {
  ngx-snackbar {
    padding: map_get($spacers, 2);
    bottom: unset;
    top: 0;

    > .ngx-snackbar {
      margin-top: unset;
      margin-bottom: map_get($spacers, 2);
      flex-direction: column;
      border-radius: $border-radius;
      place-items: flex-start;
      padding: map_get($spacers, 2);

      > .ngx-snackbar-icon {
        display: none;
      }

      > .ngx-snackbar-text {
        white-space: unset;
      }

      > .ngx-snackbar-text {
        white-space: unset;
      }

      > .ngx-snackbar-buttons {
        margin-top: map_get($spacers, 2);
      }
    }
  }
}
