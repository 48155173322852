@import "variables";

.table {
  margin: 0;

  .ngx-input {
    margin-bottom: 0 !important;
  }

  & > :not(caption) > * > * {
    padding-right: map_get($spacers, 2);
    padding-left: map_get($spacers, 2);

    &:last-child {
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  th.cell-image,
  td.cell-image {
    width: 24px;
    padding-right: 0;
  }

  th {
    font-weight: $font-weight-normal;
    font-size: $font-size-xs;
    color: var(--bs-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none !important;
  }

  td {

    > a {
      color: var(--bs-body-color);

      &:hover,
      &:focus {
        color: var(--bs-link-hover-color);
      }
    }

    &.cell-group {
      @extend .text-truncate;

      > *:not(:first-child):not(.badge) {
        font-size: $font-size-sm;
        color: var(--bs-secondary);
      }

      > a:not(.badge) {
        display: block;

        &:hover,
        &:focus {
          color: var(--bs-link-hover-color);
        }
      }
    }

    &.cell-date {
      width: 130px;
      min-width: 130px;
    }
  }

  thead ~ tbody tr {
    &:first-child td {
      border-top: none;
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  &:not(.table-borderless) tbody {
    border-top: $border-width solid var(--bs-border-color) !important;
  }

  .form-group {
    margin: 0 !important;
  }

  &.table-multi-tr {
    tbody {
      border-top: none !important;

      tr.border-less td {
        border-width: 0;
      }
    }
  }

  td,
  th {
    vertical-align: middle;
  }
}
