@import "variables";

@import "node_modules/@ctrl/ngx-emoji-mart/picker.css";

emoji-mart {

  .emoji-mart-anchor {
    cursor: pointer;
  }

  .emoji-mart {
    font-family: inherit;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  .emoji-mart-scroll {
    @include scrollbar(6px, var(--bs-primary));
    max-height: 270px;
    min-height: 0;
  }

  .emoji-mart-emoji.emoji-mart-emoji-native {
    padding: 0;

    > span {
      display: flex !important;
      width: 40px !important;
      height: 40px !important;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
