@import "variables";

.card {
  border-color: var(--bs-border-color);

  &.card-link {
    &:hover,
    &:focus {
      .card-header {
        color: var(--bs-primary) !important;
      }
    }

    &:active {
      position: relative;
      top: 3px;
    }
  }

  &.card-custom {
    border-radius: $border-radius-lg;
    overflow: hidden;
    background: $card-bg;
    position: relative;

    > .card-header,
    > .card-body,
    > .card-footer {
      padding: map_get($spacers, 4);
      background: none;
    }

    > .card-header {
      border-bottom: $border-width solid var(--bs-border-color);
      color: $card-color;
      font-weight: $font-weight-bold;
      display: flex;
      align-items: center;
      letter-spacing: 1px;

      .card-no-y-space {
        margin-top: -$card-spacer-y;
        margin-bottom: -$card-spacer-y;
      }

      .card-button {
        position: absolute;
      }

      > .nav {
        > .nav-item {
          display: flex;
          align-items: center;
          gap: map_get($spacers, 1);

          > .nav-link.active {
            background: var(--bs-body-bg);
            color: var(--bs-body-color);
          }
        }
      }
    }

    > .card-footer {
      border-top: $border-width solid var(--bs-border-color);
    }
  }

  &.card-link:not(.card-custom) {
    &:hover,
    &:focus {
      background: var(--bs-card-border-color);
    }

    &:active,
    .active {
      position: relative;
      top: 2px;
    }
  }
}

[dir=ltr] .card.card-custom > .card-header .card-button {
  right: $card-spacer-x;
}

[dir=rtl] .card.card-custom > .card-header .card-button {
  left: $card-spacer-x;
}
