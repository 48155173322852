@import "variables";

.cdk-menu {
  display: inline-flex;
  flex-direction: column;
  min-width: 180px;
  max-width: 280px;
  background-color: var(--bs-body-bg);
  padding: $dropdown-padding-y $dropdown-padding-x;
  box-shadow: var(--bs-dropdown-box-shadow);
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: $dropdown-border-radius;

  [role=heading] {
    display: block;
    padding: $dropdown-header-padding;
    margin-bottom: 0;
    font-size: $dropdown-font-size;
    color: var(--bs-secondary);
    white-space: nowrap;
  }

  .dropdown-item-text,
  .cdk-menu-item {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }

  .dropdown-item-text {
    color: var(--bs-secondary);
  }

  .cdk-menu-item {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    cursor: pointer;
    border: none;
    user-select: none;
    min-width: 64px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;

    &:hover,
    &:focus {
      background-color: var(--bs-light-bg-subtle);
      color: var(--bs-body-color);
    }

    &:active,
    &.active {
      @extend .text-bg-primary;
    }
  }
}
