@import "variables";

// Add as an addition to bootstrap.
.text-bg-light-subtle {
  background: var(--bs-light-bg-subtle);
  color: var(--bs-light-text-emphasis);
}

// When you want the page and its surrounding to have body bg.
.outer-shadow-body {
  box-shadow: 0 0 0 1000rem var(--bs-body-bg);
  background: var(--bs-body-bg);
}

// When you want the page and its surrounding to have dark subtle bg.
.outer-shadow-dark-subtle {
  box-shadow: 0 0 0 1000rem var(--bs-dark-bg-subtle);
}

// Dark theme specific changes.
[data-bs-theme=dark] {

  // Add vars to reverse colors in dark theme.
  --bs-reverse-body-bg: #{$body-bg};
  --bs-reverse-body-color: #{$body-color};
  --bs-reverse-border-color: #{$border-color};
}

// Light theme specific changes.
[data-bs-theme=light] {

  // Add vars to reverse colors in light theme.
  --bs-reverse-body-bg: #{$body-bg-dark};
  --bs-reverse-body-color: #{$body-color-dark};
  --bs-reverse-border-color: #{$border-color-dark};

  // Change colors for this theme.
  --bs-dark-bg-subtle: #f5f5ff;
  --bs-light-bg-subtle: #{$gray-200};
}
