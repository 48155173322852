.show-hover-wrapper {

  .show-hover-element {
    opacity: 0;
  }

  &:hover,
  &:focus {
    .show-hover-element {
      opacity: 1;
    }
  }
}
