@import "variables";

@import "@amirsavand/ngx-modal/assets/styles";

@include media-breakpoint-down(sm) {
  .cdk-overlay-pane {
    min-width: unset !important;
    max-width: 100% !important;
  }
}

.modal-body {
  @include scrollbar;
}
